import './App.css';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import * as ReactModal from 'react-modal';
import logo from './meter-leader-save-energy-logo-900x223pixels.png';

function App() {

  const only_unavalible = false

  const [modalOpen, setModalOpen] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [utilityId, setUtilityId] = useState('')
  const [utilityName, setUtilityName] = useState('')
  const [dataSent, setDataSent] = useState(false)
  const [ready, setReady] = useState(false)
  const [checkboxClicked, setcheckboxClicked] = useState(false)

  const [utils, setUtils] = useState([])


  useEffect(() => {
    fetch("https://pmigration-wtvnys3zja-wl.a.run.app/get-utils").then(response => {
      return response.json()
    }).then(data => {

      let util_data = JSON.parse(data).utilities

      if (only_unavalible) {
        util_data = util_data.filter((util) => util["is_available"] ? false : true)
      }

      setUtils(util_data)
      setTimeout(() => {
        setReady(true)

      }, 500)
    })
  }, [only_unavalible])

  const sendData = () => {

    fetch("https://pmigration-wtvnys3zja-wl.a.run.app/add-new-creds", {
      method: "POST",
      body: JSON.stringify({
        "username": username,
        "password": password,
        "email": email,
        "utility_id": utilityId,
        "utility_name": utilityName
      })
    })

    setModalOpen(false)
    setDataSent(true)
  }

  return (
    <div className="App">
      <header className="App-header">
        <Container maxWidth="sm">
          <Box sx={{ my: 4 }}>
            {!dataSent && <>
              <img className="ml-logo-img" src={logo} alt='MeterLeader Logo' />
              <Typography variant="h4" component="h1" gutterBottom>
                MeterLeader Utility Connection Site
              </Typography>
              <Typography variant="subtitle1" component="p" gutterBottom>
                Help MeterLeader expand its service by integrating with new electric and gas utilities.
              </Typography>
              <div
                style={{ "padding": "15px" }}
              ></div>
              <Button
                variant="contained"
                onClick={() => ready ? setModalOpen(true) : null}
                color="primary"
              >
                {ready ? "Connect My Utility Account" : "Loading..."}
              </Button>
            </>}
            {dataSent && <>
              <Typography variant="h4" component="h1" gutterBottom>
                Thank you for helping MeterLeader add more utilities.
              </Typography>
              <Typography variant="subtitle1" component="p" gutterBottom>
                We will notify you via email in approximately 2-4 weeks once we have finished integrating with your utility. In the meantime please checkout MeterLeader at www.meterleader.com
                <br /><br />
              </Typography>
            </>}
          </Box>
        </Container>

        <ReactModal
          isOpen={modalOpen}
          // onRequestClose={this.onRequestClose}
          // shouldCloseOnOverlayClick={false}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => {
            setModalOpen(false)
            setUsername('')
            setPassword('')
            setEmail('')
            setUtilityId('')
            setUtilityName('')
          }}
          className="pelm-connect-modal"
          overlayClassName="pelm-connect-modal-overlay"
          iaHideApp={false}
          shouldFocusAfterRender={false}
        >
          <Box sx={{ my: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Add your Online Utility Account Credentials
            </Typography>
            <TextField fullWidth id="standard-basic" label="Email" variant="standard" InputLabelProps={{ shrink: email.length ? true : false }} value={email} onChange={(e) => setEmail(e.target.value)} />
            <br /><br />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Utility</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={utilityId}
                label="Utility"
                onChange={(e) => setUtilityId(e.target.value)}
              >
                {utils.length && utils.map((util, i) => (
                  <MenuItem key={i} value={util.id}>{util.full_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br /><br />
            <Typography variant="h8" component="p" gutterBottom>
              If Utility is not listed please type below:
            </Typography>
            <TextField fullWidth id="standard-basic" label="Utility Name" variant="standard" InputLabelProps={{ shrink: utilityName.length ? true : false }} value={utilityName} onChange={(e) => setUtilityName(e.target.value)} />
            <br /><br />
            <TextField fullWidth id="standard-basic" label="Username (of your Online Utility Account)" variant="standard" InputLabelProps={{ shrink: username.length ? true : false }} value={username} onChange={(e) => setUsername(e.target.value)} />
            <br /><br />
            <TextField fullWidth id="standard-basic" label="Password (of your Online Utility Account)" variant="standard" InputLabelProps={{ shrink: password.length ? true : false }} value={password} onChange={(e) => setPassword(e.target.value)} />
            <br /><br />
            <div className='checkboxDiv'>
              <Checkbox onClick={() => setcheckboxClicked(!checkboxClicked)} />
              <Typography style={{ "padding-left": "10px" }}>
                I authorize MeterLeader to access my online utility account for the sole purpose of building out an energy data integration for the MeterLeader platform.
              </Typography>
            </div>
            <Button
              variant="contained"
              onClick={() => sendData()}
              color="primary"
              disabled={checkboxClicked && username && password && email && (utilityId || utilityName) ? false : true}
            >
              Submit
            </Button>
          </Box>
        </ReactModal>
      </header>
    </div>
  );
}

export default App;
